import React from "react";
import Cookies from "js-cookie";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

// Pages
import { Patients, Patient, CreateEditPatient } from "./pages/patients";

import Login from "./pages/login";
// import Dashboard from "./pages/dashboard";
import { Users } from "./pages/users";

import PatientForm from "./pages/patient-form";
import NotFound from "./pages/404";

import Questionnaires from "./pages/questionnaires";
import Reviews from "./pages/reviews";

import { Treatments, TreatmentTypes, TreatmentBodyParts } from "./pages/treatments";
import Home from "./pages/home";

import { StoreProvider } from "./Store";
import { BackTop, Alert } from "antd";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
	function PrivateRoute({ component: Component, ...rest }: { [key: string]: any }) {
		return (
			<Route
				{...rest}
				render={(props) =>
					Cookies.get("token") ? (
						<Component {...props} />
					) : (
							<Redirect
								to={{
									pathname: "/login",
									state: { from: props.location }
								}}
							/>
						)
				}
			/>
		);
	}

	return (
		<StoreProvider>
			<Router>
				<ScrollToTop>
					<div className="page">
						{process.env.REACT_APP_ENV === "development" && (
							<Alert banner message="Development Environment" type="info" />
						)}
						{process.env.REACT_APP_ENV === "staging" && (
							<Alert banner message="Staging Environment" type="info" />
						)}

						<Switch>
							<Route exact={true} path="/login" component={Login} />
							<Route exact={true} path="/patient-form" component={PatientForm} />

							<PrivateRoute exact={true} path="/" component={Patients} />

							{/* Patients */}
							<PrivateRoute exact={true} path="/patients/new" component={CreateEditPatient} />
							<PrivateRoute
								exact={true}
								path="/patients/edit/:patientId"
								component={CreateEditPatient}
							/>
							<PrivateRoute exact={true} path="/patients" component={Patients} />
							<PrivateRoute exact={true} path="/patients/:patientId" component={Patient} />

							{/* Users */}
							<PrivateRoute exact={true} path="/users" component={Users} />

							<PrivateRoute exact={true} path="/questionnaires" component={Questionnaires} />

							<PrivateRoute exact={true} path="/treatments" component={Treatments} />
							<PrivateRoute exact={true} path="/treatments/types" component={TreatmentTypes} />
							<PrivateRoute
								exact={true}
								path="/treatments/body-parts"
								component={TreatmentBodyParts}
							/>

							<PrivateRoute exact={true} path="/reviews" component={Reviews} />

							<Route component={NotFound} />
						</Switch>
						<BackTop />
					</div>
				</ScrollToTop>
			</Router>
		</StoreProvider>
	);
};

export default App;

import React, { useState, useEffect, Fragment } from "react";
import { get } from "../../services";
import Layout from "../../components/layouts/Main";
import { User } from "../../models";
import { Button, Table, Tag, Icon, message, Drawer } from "antd";
import { Link } from "react-router-dom";
import { stringToColour } from "../../lib/stringToColor";
import EditUserForm from "../../components/users/CreateEditUserForm";

const Users = () => {
	const [users, setUsers] = useState<User[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [showCreateEdit, setShowCreateEdit] = useState<boolean>(false);
	const [currentUser, setCurrentUser] = useState<string | null>(null);
	const [editing, setEditing] = useState<boolean>(false);

	const getData = async () => {
		const res = await get("/users");
		try {
			setUsers(res.data.users);
		} catch (error) {
			message.error("Unexpected Server Error: Failed to load user data.");
		}

		setLoading(false);
	};

	function onEdit(userId: string) {
		setEditing(true);
		setCurrentUser(userId);
		setShowCreateEdit(true);
	}

	function onNew() {
		setEditing(false);
		setShowCreateEdit(true);
	}

	useEffect(() => {
		getData();
	}, []);

	const columns = [
		{
			title: "Title",
			key: "patient.title",
			render: (text: string, row: User) => <Fragment>{row.title}</Fragment>
		},
		{
			title: "First Name",
			key: "user.firstName",
			sorter: (a: User, b: User) => a.firstName.localeCompare(b.firstName),
			render: (text: string, row: User) => <Fragment>{row.firstName}</Fragment>
		},
		{
			title: "Last Name",
			key: "user.lastName",
			sorter: (a: User, b: User) => a.lastName.localeCompare(b.lastName),
			render: (text: string, row: User) => <Fragment>{row.lastName}</Fragment>
		},
		{
			title: "Email",
			key: "user.email",
			sorter: (a: User, b: User) => a.email.localeCompare(b.email),
			render: (text: string, row: User) => <Fragment>{row.email}</Fragment>
		},
		{
			title: "Role",
			key: "user.role",
			sorter: (a: User, b: User) => a.role.localeCompare(b.role),
			render: (text: string, row: User) => <Tag color={stringToColour(row.role)}>{row.role}</Tag>
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: User) => (
				<Fragment>
					<Button size="default" type="link" onClick={() => onEdit(row.id)}>
						Edit
						<Icon type="edit" />
					</Button>
				</Fragment>
			)
		}
	];

	return (
		<Layout title="Users" pagetitle="Users">
			<Button type="primary" size="default" icon="plus" onClick={onNew}>
				Add new user
			</Button>

			<Table
				loading={loading}
				rowKey="id"
				style={{ marginTop: 25 }}
				dataSource={users.sort((a: User, b: User) => a.role.localeCompare(b.role))}
				columns={columns}
				pagination={false}
			/>

			<Drawer
				width={600}
				title={`${editing ? "Edit" : "Create new"} user`}
				placement="right"
				closable={true}
				destroyOnClose
				onClose={() => setShowCreateEdit(false)}
				visible={showCreateEdit}>
				<EditUserForm
					editing={editing}
					userId={currentUser || null}
					closeDrawer={() => setShowCreateEdit(false)}
					getData={getData}
				/>
			</Drawer>
		</Layout>
	);
};

export default Users;

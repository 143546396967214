import React, { FormEvent, useEffect, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import { Form, Select, Skeleton, Input, Row, Col, Button, message } from "antd";
import { User } from "../../models";
import { get, put, post } from "../../services";

interface Values {
	title: string;
	firstName: string;
	lastName: string;
	email: string;
	role: string;
	password: string;
}

interface Props extends FormComponentProps {
	userId: string | null;
	closeDrawer: () => void;
	getData: () => void;
	editing: boolean;
}

const CreateEditUserForm = ({ form, userId, closeDrawer, getData, editing }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [user, setUser] = useState<User | null>(null);

	const { getFieldDecorator } = form;
	const { Option } = Select;

	async function getUser() {
		try {
			const res = await get("/users/" + userId);
			setUser(res.data);
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load user data.");
		}
	}

	useEffect(() => {
		if (userId) getUser();
	}, [userId]);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				setLoading(true);
				const res = editing ? await put("/users/" + userId, values) : await post("/users", values);
				if (res.data) {
					await getData();
					closeDrawer();
					message.success(`Successfully ${editing ? "updated" : "created"} user`);
				} else {
					if (res.statusCode === 409) {
						message.error("A user with this email address already exists.");
					} else {
						message.error("Unexpected Server Error: Failed to save user data.");
					}
				}

				setLoading(false);
			}
		});
	}

	if ((editing && !userId) || (editing && !user)) {
		return <Skeleton active />;
	}

	return (
		<Form autoComplete="off" layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			<Row gutter={16}>
				<Col sm={24}>
					<Form.Item label="Title">
						{getFieldDecorator("title", {
							...(editing && user && { initialValue: user.title }),
							rules: [
								{
									required: true,
									message: "Please select a title"
								}
							]
						})(
							<Select allowClear>
								<Option value="Mr">Mr</Option>
								<Option value="Mrs">Mrs</Option>
								<Option value="Miss">Miss</Option>
								<Option value="Ms">Ms</Option>
								<Option value="Dr">Dr</Option>
								<Option value="Master">Master</Option>
								<Option value="Professor">Professor</Option>
								<Option value="Mx">Mx</Option>
							</Select>
						)}
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16}>
				<Col sm={12}>
					<Form.Item label="First name">
						{getFieldDecorator("firstName", {
							...(editing && user && { initialValue: user.firstName }),
							rules: [
								{
									required: true,
									message: "Please enter a first name"
								}
							]
						})(<Input />)}
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item label="Last name">
						{getFieldDecorator("lastName", {
							...(editing && user && { initialValue: user.lastName }),
							rules: [
								{
									required: true,
									message: "Please enter a last name"
								}
							]
						})(<Input />)}
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16}>
				<Col sm={12}>
					<Form.Item label="Email">
						{getFieldDecorator("email", {
							...(editing && user && { initialValue: user.email }),
							rules: [
								{
									required: true,
									message: "Please enter an email address"
								}
							]
						})(<Input type="email" />)}
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item label="Role">
						{getFieldDecorator("role", {
							...(editing && user && { initialValue: user.role }),
							rules: [
								{
									required: true,
									message: "Please select a role"
								}
							]
						})(
							<Select allowClear>
								<Option value="consultant">Consultant</Option>
								<Option value="analyst">Analyst</Option>
								<Option value="analyst-admin">Analyst (Admin)</Option>
							</Select>
						)}
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col sm={24}>
					<Form.Item label="Password">
						{getFieldDecorator("password", {
							rules: [
								{
									required: false,
									message: "Please enter a password"
								}
							]
						})(<Input.Password autoComplete="new-password" />)}
					</Form.Item>
				</Col>
			</Row>

			<Form.Item>
				<Button
					loading={loading}
					disabled={loading}
					size="large"
					type="primary"
					htmlType="submit"
					block>
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(CreateEditUserForm);

import API from "../lib/axiosConfig";

import { Response } from "../models";

export default async function get(endpoint: string) {
	try {
		const response = await API.get(endpoint);
		return response as Response;
	} catch (error) {
		throw error;
	}
}

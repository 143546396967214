import React, { FormEvent, useEffect, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import { Form, Select, Skeleton, Input, Row, Col, Button, message, Timeline, Divider, List, Icon, Popconfirm, Empty, Modal } from "antd";
import { Review, ReviewType, Questionnaire, Treatment } from "../../models";
import { get, put, post } from "../../services";
import css from "./CreateEditReviewForm.module.css";
import { sortByDate } from "../../lib/sortByDate";
import AddQuestionnaire from "../patients/Reviews/AddQuestionnaire";

interface Values {
	reviewTypeId: string;
	treatmentId: string;
	questionnaires: any[];
}

interface Props extends FormComponentProps {
	treatment: Treatment;
	closeDrawer: () => void;
	getData: () => void;
}

const CreateEditTreatmentTypeForm = ({ form, treatment, closeDrawer, getData }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);

	const [reviewId, setReviewId] = useState<string | null>(null);
	const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
	const [reviews, setReviews] = useState<Review[]>([]);
	const [reviewTypes, setReviewTypes] = useState<ReviewType[]>([]);
	const [showAddReview, setShowAddReview] = useState<boolean>(false);

	const { getFieldDecorator } = form;
	const { Option } = Select;

	async function getReviews() {
		try {
			const { data } = await get(`/treatments/${treatment.id}/reviews?include=questionnaires`);
			setReviews(data);
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load treatment data.");
		}
	}

	async function getReviewTypes() {
		try {
			const { data } = await get("/review-types");
			setReviewTypes(data);
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load treatment types data.");
		}
	}

	useEffect(() => {
		setLoading(true);
		getReviews();
		getReviewTypes();
		setLoading(false);
	}, []);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				setLoading(true);
				values.treatmentId = treatment.id;
				values.questionnaires = [];
				const res = await post("/reviews", values);
				if (res.data) {
					await getData();
					closeDrawer();
					message.success(`Successfully "created" review`);
				} else {
					if (res.statusCode === 409) {
						message.error("A review for this treatment with that review period already exists.");
					} else {
						message.error("Unexpected Server Error: Failed to save review data.");
					}
				}

				setLoading(false);
			}
		});
	}

	if (loading) {
		return (
			// <div className={css.placeholder}>
			<div >
				<Skeleton avatar paragraph={{ rows: 6 }} active />
				<Skeleton avatar paragraph={{ rows: 6 }} active />
				<Skeleton avatar paragraph={{ rows: 6 }} active />
				<Skeleton avatar paragraph={{ rows: 6 }} active />
			</div>
		);
	}

	interface ReviewProps {
		data: Review;
	}

	const Review = ({ data }: ReviewProps) => {

		const [loading, setLoading] = useState<boolean>(false);
		const [showAddQModal, setShowAddQModal] = useState<boolean>(false);

		async function delConfirm(
			reviewId: string,
			questionnaireId: string,
			questionnaires: Questionnaire[]
		) {
			let questionnairesToKeep: string[] = [];
			questionnaires.forEach((questionnaire) => {
				if (questionnaire.id !== questionnaireId) {
					questionnairesToKeep.push(questionnaire.id);
				}
			});

			try {
				setLoading(true);
				await put("/reviews/" + reviewId, { questionnaires: questionnairesToKeep });
				message.success("Questionnaire successfully deleted");
				getReviews();
			} catch (error) {
				message.error("Unexpected Server Error: Failed to delete questionnaire.");
			} finally {
				setLoading(false);
			}
		}

		function onModalClose() {
			setShowAddQModal(false);
			getReviews(); // Fetch new scores if changes are made in the modal
		}

		if (loading) {
			return <Skeleton active avatar />;
		}

		return (
			<Timeline.Item
				key={data.id}
				dot={
					<div className={css.reviewDot}>
					</div>
				}
			>
				<h3 className={css.reviewTitle}>{data.reviewType.name}</h3>

				<Button
					style={{ marginLeft: 0 }}
					icon="plus"
					onClick={() => setShowAddQModal(true)}
				>
					Add Questionnaires
				</Button>

				<Divider dashed style={{ marginBottom: 0 }} />

				<List
					itemLayout="horizontal"
					dataSource={data.questionnaires.sort((q1, q2) => {
						// Sort by order
						// If the first item has a higher number, move it down
						// If the first item has a lower number, move it up
						if (q1.order > q2.order) return 1;
						if (q1.order < q2.order) return -1;

						// If the order number is the same between both items, sort alphabetically
						// If the first item comes first in the alphabet, move it up
						// Otherwise move it down
						if (q1.name > q2.name) return 1;
						if (q1.name < q2.name) return -1;

						return 0;
					})}
					renderItem={(item: Questionnaire) => {
						return (
							<List.Item>
								<List.Item.Meta title={item.name} />
								<Popconfirm
									title="Are you sure you want to delete this questionnaire? Existing scores will be deleted."
									onConfirm={() => delConfirm(data.id, item.id, data.questionnaires)}
									okText="Yes"
									cancelText="No">
									<Icon
										// className={[css.actionButton, css.delete].join(" ")}
										style={{ fontSize: 18 }}
										type="delete"
									/>
								</Popconfirm>
							</List.Item>
						);
					}}
				/>
				<Modal
					width={650}
					visible={showAddQModal}
					onCancel={onModalClose}
					destroyOnClose
					footer={null}
					title="Add questionnaire(s)">
					<AddQuestionnaire closeModal={onModalClose} review={data} getReviews={getReviews} />
				</Modal>
			</Timeline.Item>
		);
	};

	console.log(reviews)

	return (
		<section >
			<h2 style={{ marginBottom: 25 }}>
				Reviews: {treatment.treatmentType.name} {treatment.bodyPart.name}{" "}
			</h2>

			<Timeline>
				{reviews ? (
					reviews.sort((a: any, b: any) => Number(new Date(a.reviewType.daysFromTreatment)) - Number(new Date(b.reviewType.daysFromTreatment)))
					.map((review: Review) => (
						<Review key={review.id} data={review} />
					))
				) : (
					<Empty imageStyle={{ textAlign: "left" }} />
				)}
			</Timeline>
			{
				!showAddReview ?
				(
					<div className={css.addReviewButton}>
						<Button
							style={{ marginLeft: 0 }}
							icon="plus"
							onClick={() => setShowAddReview(true)}
						>
							Add Review
						</Button>
					</div>
				): null
			}
			{
				showAddReview ?
				(
					<div className={css.addReviewForm}>
						<Form
							labelCol={{ xs: { span: 24 }, sm: { span: 4 }	}}
							wrapperCol = {{ xs: { span: 24 }, sm: { span: 20 } }}
							autoComplete="off"
							onSubmit={(event) => handleSubmit(event)}
						>
							<Row gutter={24}>
								<Col span={24}>
									<Form.Item label="Reviews">
										{getFieldDecorator("reviewTypeId", {
											rules: [
												{
													required: true,
													message: "Please select a review period"
												}
											]
										})(
											<Select
												allowClear
												showSearch
												optionFilterProp="children"
												filterOption={(input: any, option: any) =>
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
												placeholder="Select review"
												disabled={!reviewTypes.length}
												loading={!reviewTypes.length ? true : false}
											>
												<Option disabled value="">
													Select review
												</Option>
												{reviewTypes
													.map((reviewType: ReviewType) => {
														return (
															<Option key={reviewType.id} value={reviewType.id}>
																{reviewType.name}
															</Option>
														);
													})}
											</Select>
										)}
									</Form.Item>
								</Col>
							</Row>
							<Row>
          			<Col span={10} >
									<Button
										style={{ marginLeft: 0, marginRight: 0 }}
										loading={loading}
										disabled={loading}
										size="large"
										type="primary"
										htmlType="submit"
										block>
										Save
									</Button>
								</Col>
								<Col span={10} offset={4} >
									<Button
										style={{ marginLeft: 0, marginRight: 0 }}
										size="large"
										onClick={() => setShowAddReview(false)}
										block
									>
										Cancel
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				) : null
			}
		</section>
	);
};

export default Form.create<Props>()(CreateEditTreatmentTypeForm);

import React, { useState, useEffect, FormEvent, Fragment } from "react";
import { TreatmentType } from "../../models";
import { get, destroy } from "../../services";

import Layout from "../../components/layouts/Main";
import { List, Button, Table, Drawer, message, Icon, Popconfirm } from "antd";
import CreateEditTreatmentTypeForm from "../../components/treatments/CreateEditTreatmentTypeForm";

import css from "./css/TreatmentType.module.css";

const TreatmentTypes = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [showCreateEdit, setShowCreateEdit] = useState<boolean>(false);
	const [selected, setSelected] = useState<any>(null);
	const [editing, setEditing] = useState<boolean>(false);
	const [currentTreatmentType, setCurrentTreatmentType] = useState<string | null>(null);
	const [treatmentTypes, setTreatmentTypes] = useState<TreatmentType[]>([]);

	// Fetch data
	const getData = async () => {
		const { data } = await get("/treatment-types");
		setTreatmentTypes(data);
		setLoading(false);
	};

	function onEdit(treatmentTypeId: string) {
		setEditing(true);
		setCurrentTreatmentType(treatmentTypeId);
		setShowCreateEdit(true);
	}

	function onNew() {
		setEditing(false);
		setShowCreateEdit(true);
	}

	async function deleteTreatmentType(treatmentTypeId: string) {
		try {
			const res = await destroy("/treatment-types/" + treatmentTypeId);
			if (res.data) {
				const updatedTreatmentTypes = treatmentTypes.filter(
					(treatmentType: TreatmentType) => treatmentType.id !== treatmentTypeId
				);
				setTreatmentTypes(updatedTreatmentTypes);
				message.success("Treatment type successfully deleted");
			} else {
				message.error("Unexpected Server Error: Failed to delete treatment type.");
			}
		} catch (error) {
			message.error("Unexpected Server Error: Failed to delete treatment type.");
		}
	}

	// Get data on mount
	useEffect(() => {
		getData();
	}, []);

	const columns = [
		{
			title: "Name",
			key: "treatmentType.name",
			sorter: (a: TreatmentType, b: TreatmentType) => a.name.localeCompare(b.name),
			render: (text: string, row: TreatmentType) => <Fragment>{row.name}</Fragment>
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: TreatmentType) => (
				<Fragment>
					<Button size="default" type="link" onClick={() => onEdit(row.id)}>
						Edit
						<Icon type="edit" />
					</Button>
					<Popconfirm
						title="Are you sure delete this treatment?"
						onConfirm={() => deleteTreatmentType(row.id)}
						okText="Yes"
						cancelText="No">
						<Button size="default" type="link" style={{ color: "#a20909" }}>
							Delete
							<Icon type="delete" />
						</Button>
					</Popconfirm>
				</Fragment>
			)
		}
	];

	return (
		<Layout title="Treatment Types" pagetitle="Treatment Types">
			<Button type="primary" size="default" icon="plus" onClick={onNew}>
				Add new treatment type
			</Button>

			<Table
				className="treatment-types-table"
				loading={loading}
				rowKey="id"
				style={{ marginTop: 25 }}
				rowClassName={(record, index) => [css.row, record.id ? css.active : ""].join(" ")}
				dataSource={treatmentTypes.sort((b1: TreatmentType, b2: TreatmentType) =>
					b1.name.localeCompare(b2.name)
				)}
				columns={columns}
				pagination={false}
			/>

			<Drawer
				width={600}
				title={`${editing ? "Edit" : "Create new"} treatment type`}
				placement="right"
				closable={true}
				destroyOnClose
				onClose={() => setShowCreateEdit(false)}
				visible={showCreateEdit}>
				<CreateEditTreatmentTypeForm
					editing={editing}
					treatmentTypeId={currentTreatmentType || null}
					closeDrawer={() => setShowCreateEdit(false)}
					getData={getData}
				/>
			</Drawer>
		</Layout>
	);
};

export default TreatmentTypes;

import React, { useEffect, useState, Fragment } from "react";
import { TreatmentRecord, ReviewRecord, Questionnaire, Patient, Answer } from "../../../models";
import { get, put } from "../../../services";
import {
	message,
	Timeline,
	Skeleton,
	Icon,
	Empty,
	List,
	Badge,
	Button,
	Divider,
	Popconfirm,
	Tag,
	Modal,
	Tooltip
} from "antd";
import css from "./Reviews.module.css";
import { sortByDate } from "../../../lib/sortByDate";
import moment from "moment";
import AddQuestionnaire from "./AddQuestionnaire";
import { StoreState } from "../../../Store";
import SendQuestionnaires from "./SendQuestionnaires";
import EditQuestionnaire from "./EditQuestionnaire";

interface Props {
	initialTreatment: TreatmentRecord;
	patient: Patient;
}

const Reviews = ({ initialTreatment, patient }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [reviews, setReviews] = useState<ReviewRecord[] | null>(null);

	const state = React.useContext(StoreState);

	const treatment = state.patient.currentTreatment || initialTreatment;

	async function getReviews() {
		setLoading(true);
		try {
			const res = await get(
				`/treatment-records/${treatment.id}/review-records?include=questionnaires`
			);
			setReviews(res.data);

			return res.data;
		} catch (error) {
			message.error("Unexpected Server Error: Failed to load review data.");
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (treatment) getReviews();
	}, [treatment]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!treatment) {
		return (
			<section className="reviews">
				<h2 className={css.title}>
					<Icon type="audit" />
					Reviews
				</h2>
				<Empty description="No treatments yet" />
			</section>
		);
	}

	if (loading || !reviews) {
		return (
			<div className={css.placeholder}>
				<Skeleton avatar paragraph={{ rows: 6 }} active />
				<Skeleton avatar paragraph={{ rows: 6 }} active />
				<Skeleton avatar paragraph={{ rows: 6 }} active />
				<Skeleton avatar paragraph={{ rows: 6 }} active />
			</div>
		);
	}

	interface ReviewProps {
		data: ReviewRecord;
	}

	const Review = ({ data }: ReviewProps) => {
		const [showAddQModal, setShowAddQModal] = useState<boolean>(false);
		const [showEditQModal, setShowEditQModal] = useState<boolean>(false);
		const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
		const [questionnaireToEdit, setQuestionnaireToEdit] = useState<Questionnaire | null>(null);
		const [loading, setLoading] = useState<boolean>(false);

		async function delConfirm(
			reviewId: string,
			questionnaireId: string,
			questionnaires: Questionnaire[]
		) {
			let questionnairesToKeep: string[] = [];
			questionnaires.forEach((questionnaire) => {
				if (questionnaire.id !== questionnaireId) {
					questionnairesToKeep.push(questionnaire.id);
				}
			});

			try {
				setLoading(true);
				await put("/review-records/" + reviewId, { questionnaires: questionnairesToKeep });
				message.success("Questionnaire successfully deleted");
				getReviews();
			} catch (error) {
				message.error("Unexpected Server Error: Failed to delete questionnaire.");
			} finally {
				setLoading(false);
			}
		}

		function onModalClose() {
			setShowAddQModal(false);
			setShowEditQModal(false);
			setShowEmailModal(false);
			setQuestionnaireToEdit(null);
			getReviews(); // Fetch new scores if changes are made in the modal
		}

		async function getQuestionnaireToEdit(questionnaireId: string) {
			try {
				const res = await get(`/review-records/${data.id}/questionnaires/${questionnaireId}`);
				setQuestionnaireToEdit(res.data);
			} catch (error) {
				// Handle error
				console.log(error);
			}
		}

		function editQuestionnaire(questionnaire: Questionnaire) {
			getQuestionnaireToEdit(questionnaire.id);
			setShowEditQModal(true);
		}

		const reviewDateString = new Date(data.startDate).toLocaleDateString("en-US", {
			weekday: "short",
			year: "numeric",
			month: "short",
			day: "numeric"
		});

		interface iScores {
			answers: Answer[];
			name: string;
			calculatedScore: number | null;
		}

		const Scores = ({ answers, name, calculatedScore }: iScores) => {
			let scores: { [key: string]: number | null } = {
				score: null,
				scoreADL: null,
				scoreMental: null,
				scorePain: null,
				scorePhysical: null,
				scoreQOL: null,
				scoreSports: null,
				scoreSymptoms: null,
				scoreWOMACSADL: null,
				scoreWOMACSPain: null,
				scoreWOMACSStiffness: null,
				scoreWOMACSTotal: null,
				scoreWork: null
			};

			function round(number: number) {
				return Math.round(number);
			}

			if (answers.length > 0) {
				answers.forEach((answer: Answer) => {
					// Loop over types of scores and increment score
					Object.keys(scores).forEach((score) => {
						scores[score] = !scores[score]
							? (answer as any)[score]
							: scores[score] + ((answer as any)[score] || 0);
					});
				});

				if (name.includes("KOOS")) {
					return (
						<Tag color="geekblue">
							<Tooltip title="Score Pain">
								P:{scores["scorePain"] !== null ? round(scores["scorePain"]) : "-"}
							</Tooltip>
							&nbsp;
							<Tooltip title="Score Symptoms">
								S:{scores["scoreSymptoms"] !== null ? round(scores["scoreSymptoms"]) : "-"}
							</Tooltip>
							&nbsp;
							<Tooltip title="Score ADL">
								A:{scores["scoreADL"] !== null ? round(scores["scoreADL"]) : "-"}
							</Tooltip>
							&nbsp;
							<Tooltip title="Score Sport">
								SP:{scores["scoreSports"] !== null ? round(scores["scoreSports"]) : "-"}
							</Tooltip>
							&nbsp;
							<Tooltip title="Score QOL">
								Q:{scores["scoreQOL"] !== null ? round(scores["scoreQOL"]) : "-"}
							</Tooltip>
							&nbsp;
							<Tooltip title="Score WOMACS Pain">
								{scores["scoreWOMACSPain"] !== null ? round(scores["scoreWOMACSPain"]) : "-"}/
							</Tooltip>
							<Tooltip title="Score WOMACS Stiffness">
								{scores["scoreWOMACSStiffness"] !== null
									? round(scores["scoreWOMACSStiffness"])
									: "-"}
								/
							</Tooltip>
							<Tooltip title="Score WOMACS ADL">
								{scores["scoreWOMACSADL"] !== null ? round(scores["scoreWOMACSADL"]) : "-"}/
							</Tooltip>
							<Tooltip title="Score WOMACS Total">
								{scores["scoreWOMACSTotal"] !== null ? round(scores["scoreWOMACSTotal"]) : "-"}
							</Tooltip>
						</Tag>
					);
				}

				if (name.includes("EQ-5D")) {
					return (
						<Tag color="geekblue">{calculatedScore !== null && calculatedScore.toFixed(3)}</Tag>
					);
				}

				return (
					<Fragment>
						{Object.keys(scores).map((score) => {
							const currentScore = scores[score];
							let color: string;

							switch (score) {
								case "score":
									color = "#007cce";
									break;

								case "scoreSports":
									color = "#f39c12";
									break;

								case "scorePhysical":
									color = "#0b8c6e";
									break;

								case "scoreMental":
									color = "#782b86";
									break;

								case "scoreWork":
									color = "#e74c3c";
									break;

								default:
									color = "#868686";
							}

							return (
								currentScore !== null && (
									<Badge
										key={score}
										overflowCount={9999}
										style={{ backgroundColor: color, marginRight: 5 }}
										count={currentScore.toFixed(0)}
										showZero
									/>
								)
							);
						})}
					</Fragment>
				);
			}

			return (
				<p style={{ margin: 0 }}>
					<em>No scores captured yet</em>
				</p>
			);
		};

		const overdue = moment()
			.subtract(7, "days")
			.isSameOrAfter(moment(data.startDate));

		if (loading) {
			return <Skeleton active avatar />;
		}

		return (
			<Timeline.Item
				key={data.id}
				dot={
					<div className={[css.reviewDot, overdue ? css.overdue : ""].join(" ")}>
						{reviewDateString}
					</div>
				}>
				<h3 className={css.reviewTitle}>{data.reviewType.name}</h3>

				{patient.email || patient.otherEmails ? (
					<Button type="primary" icon="mail" onClick={() => setShowEmailModal(true)}>
						Send Questionnaires
					</Button>
				) : null}

				<Button
					style={{ marginLeft: patient.email || patient.otherEmails ? 15 : 0 }}
					icon="plus"
					onClick={() => setShowAddQModal(true)}>
					Add Questionnaires
				</Button>

				<Divider dashed style={{ marginBottom: 0 }} />

				<List
					itemLayout="horizontal"
					dataSource={data.questionnaires.sort((q1, q2) => {
						// Sort by order
						// If the first item has a higher number, move it down
						// If the first item has a lower number, move it up
						if (q1.order > q2.order) return 1;
						if (q1.order < q2.order) return -1;

						// If the order number is the same between both items, sort alphabetically
						// If the first item comes first in the alphabet, move it up
						// Otherwise move it down
						if (q1.name > q2.name) return 1;
						if (q1.name < q2.name) return -1;

						return 0;
					})}
					renderItem={(item: Questionnaire) => {
						return (
							<List.Item>
								<List.Item.Meta
									title={item.name}
									description={
										<Scores
											name={item.name}
											answers={item.answers}
											calculatedScore={item.calculatedScore}
										/>
									}
								/>
								<Icon
									className={css.actionButton}
									style={{ fontSize: 18 }}
									type="eye"
									onClick={() => editQuestionnaire(item)}
								/>
								<Popconfirm
									title="Are you sure you want to delete this questionnaire? Existing scores will be deleted."
									onConfirm={() => delConfirm(data.id, item.id, data.questionnaires)}
									okText="Yes"
									cancelText="No">
									<Icon
										className={[css.actionButton, css.delete].join(" ")}
										style={{ fontSize: 18 }}
										type="delete"
									/>
								</Popconfirm>
							</List.Item>
						);
					}}
				/>

				<Modal
					width={650}
					visible={showAddQModal}
					onCancel={onModalClose}
					destroyOnClose
					footer={null}
					title="Add questionnaire(s)">
					<AddQuestionnaire closeModal={onModalClose} review={data} getReviews={getReviews} />
				</Modal>

				<Modal
					width={850}
					visible={showEditQModal}
					onCancel={onModalClose}
					destroyOnClose
					footer={null}
					title={`Editing questionnaire "${data.reviewType.name}"`}>
					<EditQuestionnaire
						closeModal={onModalClose}
						patient={patient}
						review={data}
						questionnaire={questionnaireToEdit!}
					/>
				</Modal>

				<Modal
					width={650}
					visible={showEmailModal}
					onCancel={onModalClose}
					destroyOnClose
					footer={null}
					title={`Sending "${data.reviewType.name}" questionnaires`}>
					<SendQuestionnaires closeModal={onModalClose} patient={patient} review={data} />
				</Modal>
			</Timeline.Item>
		);
	};

	if (loading) {
		return <Skeleton active avatar />;
	}

	return (
		<section className={[css.reviews, "reviews"].join(" ")}>
			<h2 className={css.title}>
				<Icon type="solution" style={{ color: "#757b86" }} />
				Reviews: {treatment.treatment.treatmentType.name} {treatment.treatment.bodyPart.name}{" "}
				{treatment.side && treatment.side !== "NR" && `(${treatment.side})`}
			</h2>

			<section className={css.key}>
				<h4 className={css.keyTitle}>Key</h4>
				<div className={css.keyItems}>
					<Tag color="#007cce">Standard Score</Tag>
					<Tag color="#0b8c6e">Physical Score</Tag>
					<Tag color="#782b86">Mental Score</Tag>
					<Tag color="#e74c3c">Work Score</Tag>
					<Tag color="#f39c12">Sports Score</Tag>
				</div>
			</section>

			<Timeline>
				{reviews ? (
					sortByDate(reviews, "startDate", "ASC").map((review: ReviewRecord) => (
						<Review key={review.id} data={review} />
					))
				) : (
					<Empty imageStyle={{ textAlign: "left" }} />
				)}
			</Timeline>
		</section>
	);
};

export default Reviews;

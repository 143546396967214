import React, { useState, useEffect, FormEvent, Fragment } from "react";
import { BodyPart } from "../../models";
import { get, destroy } from "../../services";

import { Button, Drawer, Table, Icon, Popconfirm, message } from "antd";
import Layout from "../../components/layouts/Main";
import CreateEditBodyPartForm from "../../components/treatments/CreateEditBodyPartForm";

import css from "./css/BodyPart.module.css"

const BodyParts = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [showCreateEdit, setShowCreateEdit] = useState<boolean>(false);
	const [selected, setSelected] = useState<any>(null);
	const [editing, setEditing] = useState<boolean>(false);
	const [currentBodyPart, setCurrentBodyPart] = useState<string | null>(null);
	const [bodyParts, setBodyParts] = useState<BodyPart[]>([]);

	// Fetch data
	const getData = async () => {
		const { data } = await get("/body-parts");
		setBodyParts(data);
		setLoading(false);
	};

	function onEdit(bodyPartId: string) {
		setEditing(true);
		setCurrentBodyPart(bodyPartId);
		setShowCreateEdit(true);
	}

	function onNew() {
		setEditing(false);
		setShowCreateEdit(true);
	}

	async function deleteBodyPart(bodyPartId: string) {
		try {
			const res = await destroy("/body-parts/" + bodyPartId);
			if (res.data) {
				const updatedBodyParts = bodyParts.filter((bodyPart: BodyPart) => bodyPart.id !== bodyPartId);
				setBodyParts(updatedBodyParts);
				message.success("Body part successfully deleted");
			} else {
				message.error("Unexpected Server Error: Failed to delete body part.");
			}
		} catch (error) {
			message.error("Unexpected Server Error: Failed to delete body part.");
		}
	}
	
	useEffect(() => {
		getData();
	}, []);

	const columns = [
		{
			title: "Name",
			key: "bodyPart.name",
			sorter: (a: BodyPart, b: BodyPart) => a.name.localeCompare(b.name),
			render: (text: string, row: BodyPart) => <Fragment>{row.name}</Fragment>
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: BodyPart) => (
				<Fragment>
					<Button size="default" type="link" onClick={() => onEdit(row.id)}>
						Edit
						<Icon type="edit" />
					</Button>
					<Popconfirm
						title="Are you sure delete this treatment?"
						onConfirm={() => deleteBodyPart(row.id)}
						okText="Yes"
						cancelText="No">
						<Button size="default" type="link" style={{ color: "#a20909" }}>
							Delete
							<Icon type="delete" />
						</Button>
					</Popconfirm>
				</Fragment>
			)
		}
	]

	return (
		<Layout title="Body Parts" pagetitle="Body Parts">
			<Button type="primary" size="default" icon="plus" onClick={onNew}>
				Add new body part
			</Button>

			<Table
				className="body-parts-table"
				loading={loading}
				rowKey="id"
				style={{ marginTop: 25 }}
				rowClassName={(record, index) => [css.row, record.id ? css.active : ""].join(" ")}
				dataSource={bodyParts.sort((b1: BodyPart, b2: BodyPart) =>
					b1.name.localeCompare(b2.name)
				)}
				columns={columns}
				pagination={false}
			/>
			
			<Drawer
				width={600}
				title={`${editing ? "Edit" : "Create new"} body part`}
				placement="right"
				closable={true}
				destroyOnClose
				onClose={() => setShowCreateEdit(false)}
				visible={showCreateEdit}>
				<CreateEditBodyPartForm
					editing={editing}
					bodyPartId={currentBodyPart || null}
					closeDrawer={() => setShowCreateEdit(false)}
					getData={getData}
				/>
			</Drawer>
		</Layout>
	);
};

export default BodyParts;

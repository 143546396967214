import React, { useState, useEffect, Fragment } from "react";
import { get, destroy } from "../../services";

import css from "./css/Patient.module.css";

// Components
import { Button, Icon, Table, message, Popconfirm, Tag } from "antd";
import Layout from "../../components/layouts/Main";
import { Patient } from "../../models";
import { Link } from "react-router-dom";
import moment from "moment";
import { stringToColour } from "../../lib/stringToColor";

const Patients = () => {
	const [patients, setPatients] = useState<Patient[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	async function getPatients() {
		try {
			const res = await get("/patients?include=researchAnalyst");
			setPatients(res.data);
		} catch {
			message.error("Unexpected Server Error: Failed to load patient data.");
		}

		setLoading(false);
	}

	async function deletePatient(patientId: string) {
		try {
			const res = await destroy("/patients/" + patientId);
			if (res.data) {
				const updatedPatients = patients.filter((patient: Patient) => patient.id !== patientId);
				setPatients(updatedPatients);
				message.success("Patient successfully deleted");
			} else {
				message.error("Unexpected Server Error: Failed to delete patient.");
			}
		} catch (error) {
			message.error("Unexpected Server Error: Failed to delete patient.");
		}
	}

	// Get data on mount
	useEffect(() => {
		getPatients();
	}, []);

	const columns = [
		{
			title: "Title",
			key: "patient.title",
			sorter: (a: Patient, b: Patient) => a.title.localeCompare(b.title),
			render: (text: string, row: Patient) => <Fragment>{row.title}</Fragment>
		},
		{
			title: "First Name",
			key: "patient.firstName",

			sorter: (a: Patient, b: Patient) => a.firstName.localeCompare(b.firstName),
			render: (text: string, row: Patient) => (
				<div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{row.firstName}</div>
			)
		},
		{
			title: "Last Name",
			key: "patient.lastName",

			sorter: (a: Patient, b: Patient) => a.lastName.localeCompare(b.lastName),
			render: (text: string, row: Patient) => (
				<div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{row.lastName}</div>
			)
		},
		{
			title: "Email",
			key: "patient.email",

			sorter: (a: Patient, b: Patient) => {
				const emailA = a.email || "";
				const emailB = b.email || "";
				return emailA.localeCompare(emailB);
			},
			render: (text: string, row: Patient) => (
				<div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{row.email}</div>
			)
		},
		{
			title: "Hospital Ref",
			key: "patient.hospitalRef",

			sorter: (a: Patient, b: Patient) =>
				a.hospitalRef.toLowerCase() > b.hospitalRef.toLowerCase() ? 1 : -1,
			render: (text: string, row: Patient) => (
				<div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{row.hospitalRef}</div>
			)
		},
		{
			title: "Created At",
			key: "patient.createdAt",
			sorter: (a: Patient, b: Patient) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
			render: (text: string, row: Patient) => (
				<Fragment>
					{moment(row.createdAt)
						.format("DD/MM/YYYY")
						.toString()}
				</Fragment>
			)
		},
		{
			title: "Assigned Analyst",
			key: "patient.researchAnalyst",
			sorter: (a: Patient, b: Patient) => {
				const analystA =
					(a.researchAnalyst && `${a.researchAnalyst.firstName} ${a.researchAnalyst.lastName}`) ||
					"";
				const analystB =
					(b.researchAnalyst && `${b.researchAnalyst.firstName} ${b.researchAnalyst.lastName}`) ||
					"";
				return analystA.localeCompare(analystB);
			},
			render: (text: string, row: Patient) => (
				<Fragment>
					<Tag color={stringToColour((row.researchAnalyst && row.researchAnalyst.email) || "")}>
						{row.researchAnalyst &&
							`${row.researchAnalyst.title} ${row.researchAnalyst.firstName} ${row.researchAnalyst.lastName}`}
					</Tag>
				</Fragment>
			)
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: Patient) => (
				<Fragment>
					<Link to={"/patients/" + row.id}>
						<Button size="default" type="link">
							View
							<Icon type="eye" />
						</Button>
					</Link>
					<Link to={"/patients/edit/" + row.id}>
						<Button size="default" type="link">
							Edit
							<Icon type="edit" />
						</Button>
					</Link>
					<Popconfirm
						title="Are you sure delete this patient?"
						onConfirm={() => deletePatient(row.id)}
						okText="Yes"
						cancelText="No">
						<Button size="default" type="link" style={{ color: "#a20909" }}>
							Delete
							<Icon type="delete" />
						</Button>
					</Popconfirm>
				</Fragment>
			)
		}
	];

	return (
		<Layout title="Patients" pagetitle="Patients">
			<Link to="/patients/new">
				<Button type="primary" size="default" icon="plus">
					Add new patient
				</Button>
			</Link>

			<Table
				className="patients-table"
				loading={loading}
				rowKey="id"
				style={{ marginTop: 25 }}
				rowClassName={(record, index) => [css.row, record.id ? css.active : ""].join(" ")}
				dataSource={patients.sort(
					(a: Patient, b: Patient) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
				)}
				columns={columns}
				pagination={false}
			/>
		</Layout>
	);
};

export default Patients;

import React, { ReactNode } from "react";
import styles from "./PatientForm.module.css";
import logo from "../../../static/images/logo-white.svg";
import { Phone, Email } from "../../icons";

interface Props {
	children: ReactNode;
}

const PatientForm = ({ children }: Props) => {
	return (
		<div className={styles.page}>
			<header className={styles.header}>
				<div className={styles["side-panel"]}>
					<img className={styles.logo} src={logo} alt="Medical Outcomes" />
				</div>
			</header>

			<main className={styles.content}>{children}</main>

			<footer className={styles.footer}>
				<p>
					<small>&copy; {new Date().getFullYear()} Medical Outcomes</small>
				</p>
			</footer>
		</div>
	);
};

export default PatientForm;

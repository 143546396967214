import React, { useState, useEffect, Fragment } from "react";
import { Treatment } from "../../models";
import { get, destroy } from "../../services";
import Layout from "../../components/layouts/Main";
import { Button, Table, message, Drawer, Icon, Popconfirm } from "antd";
import CreateEditTreatmentForm from "../../components/treatments/CreateEditTreatmentForm";
import CreateEditReviewForm from "../../components/reviews/CreateEditReviewForm";

import css from "./css/Treatment.module.css";

const Treatments = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [treatments, setTreatments] = useState<Treatment[]>([]);
	const [showCreateEdit, setShowCreateEdit] = useState<string | null>(null);
	const [currentTreatment, setCurrentTreatment] = useState<Treatment | null>(null);
	const [editing, setEditing] = useState<boolean>(false);

	// Fetch data
	const getData = async () => {
		try {
			const res = await get("/treatments");
			setTreatments(res.data);
		} catch (error) {
			message.error("Unexpected Server Error: Failed to load treatments data.");
		} finally {
			setLoading(false);
		}
	};

	function onEdit(treatment: Treatment, editor: string) {
		setEditing(true);
		setCurrentTreatment(treatment);
		setShowCreateEdit(editor);
	}

	function onNew() {
		setEditing(false);
		setShowCreateEdit("treatment");
	}

	async function deleteTreatment(treatmentId: string) {
		try {
			const res = await destroy("/treatments/" + treatmentId);
			if (res.data) {
				const updatedTreatments = treatments.filter(
					(treatment: Treatment) => treatment.id !== treatmentId
				);
				setTreatments(updatedTreatments);
				message.success("Treatment successfully deleted");
			} else {
				message.error("Unexpected Server Error: Failed to delete treatment.");
			}
		} catch (error) {
			message.error("Unexpected Server Error: Failed to delete treatment.");
		}
	}

	// Get data on mount
	useEffect(() => {
		getData();
	}, []);

	const columns = [
		{
			title: "Treatment type",
			key: "treatment.treatmentType.name",
			sorter: (a: Treatment, b: Treatment) =>
				a.treatmentType.name.localeCompare(b.treatmentType.name),
			render: (text: string, row: Treatment) => <Fragment>{row.treatmentType.name}</Fragment>,
			width: 200
		},
		{
			title: "Body part",
			key: "treatment.bodyPart.name",
			sorter: (a: Treatment, b: Treatment) => a.bodyPart.name.localeCompare(b.bodyPart.name),
			render: (text: string, row: Treatment) => <Fragment>{row.bodyPart.name}</Fragment>,
			width: 500
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: Treatment) => (
				<Fragment>
					<Button size="default" type="link" onClick={() => onEdit(row, "treatment")}>
						Edit
						<Icon type="edit" />
					</Button>
					<Button size="default" type="link" onClick={() => onEdit(row, "review")}>
						Manage Review Periods
						<Icon type="edit" />
					</Button>
					<Popconfirm
						title="Are you sure delete this treatment?"
						onConfirm={() => deleteTreatment(row.id)}
						okText="Yes"
						cancelText="No">
						<Button size="default" type="link" style={{ color: "#a20909" }}>
							Delete
							<Icon type="delete" />
						</Button>
					</Popconfirm>
				</Fragment>
			)
		}
	];

	return (
		<Layout title="Treatments" pagetitle="Treatments">
			<Button type="primary" size="default" icon="plus" onClick={onNew}>
				Add new treatment
			</Button>

			<Table
				className="treatments-table"
				loading={loading}
				rowKey="id"
				style={{ marginTop: 25 }}
				rowClassName={(record, index) => [css.row, record.id ? css.active : ""].join(" ")}
				dataSource={treatments.sort((t1: Treatment, t2: Treatment) =>
					t1.treatmentType.name.localeCompare(t2.treatmentType.name)
				)}
				columns={columns}
				pagination={false}
			/>

			<Drawer
				width={900}
				title={`${editing ? "Edit" : "Create new"} treatment`}
				placement="right"
				closable={true}
				destroyOnClose
				onClose={() => {
						setShowCreateEdit(null);
						setCurrentTreatment(null);
					}
				}
				visible={showCreateEdit !== null}>
					{
						showCreateEdit === "treatment" ?
							(
								<CreateEditTreatmentForm
									editing={editing}
									treatment={currentTreatment}
									closeDrawer={() => setShowCreateEdit(null)}
									getData={getData}
								/>
							)	: null
					}
					{
						showCreateEdit === "review" ?
							(
								<CreateEditReviewForm
									treatment={currentTreatment!}
									closeDrawer={() => setShowCreateEdit(null)}
									getData={getData}
								/>
							) : null
					}
			</Drawer>
		</Layout>
	);
};

export default Treatments;

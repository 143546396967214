import React, { useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { withRouter, RouteComponentProps } from "react-router";
import css from "./UserProfile.module.css";
import { get } from "../../services";
import { StoreDispatch, StoreState } from "../../Store";
import { Avatar, Skeleton, Button } from "antd";
import { stringToColour } from "../../lib/stringToColor";

const UserProfile = ({ history }: RouteComponentProps) => {
	const state = useContext(StoreState);
	const dispatch = useContext(StoreDispatch);

	const { user } = state;

	// Fetch User
	async function getData() {
		const response = await get("/users/me");
		const data = response.data;
		if (data) {
			dispatch({ type: "SET_USER", payload: data });
		} else {
			logout();
		}
	}

	useEffect(() => {
		if (!user) {
			getData();
		}
	});

	if (!user)
		return (
			<div className={css.placeholder}>
				<Skeleton active avatar title={{ width: 200 }} paragraph={{ width: 250, rows: 1 }} />
			</div>
		);

	const logout = () => {
		Cookies.remove("token");
		history.push("/login");
		dispatch({ type: "DESTROY_STORE" });
	};

	return (
		<div className={css.profile}>
			<Avatar size={48} icon="user" style={{ backgroundColor: stringToColour(user.email) }} />
			<div className={css.userInfo}>
				<p className={css.userName}>{user.firstName}</p>
				<Button className={css.logout} onClick={logout} type="link">
					Log Out
				</Button>
			</div>
		</div>
	);
};

export default withRouter(UserProfile);

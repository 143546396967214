import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Main";
import CreateEditPatientForm from "../../components/patients/CreateEditPatientForm";
import { RouteComponentProps } from "react-router";
import { Patient } from "../../models";
import { message, Skeleton } from "antd";
import { get } from "../../services";

interface Match {
	patientId: string;
}

const CreateEditPatient = ({ match }: RouteComponentProps<Match>) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [patient, setPatient] = useState<null | Patient>(null);

	async function getPatient() {
		try {
			const res = await get("/patients/" + match.params.patientId);
			setPatient(res.data);
			if (res.data) {
				setLoading(false);
			} else {
				message.error("Unexpected Server Error: Failed to load patient data.");
			}
		} catch (error) {
			message.error("Unexpected Server Error: Failed to load patient data.");
		}
	}

	useEffect(() => {
		if (match.params.patientId) {
			getPatient();
		} else {
			setLoading(false);
		}
	}, [match.params.patientId]);

	if (loading) {
		return (
			<Layout>
				<Skeleton paragraph={{ rows: 20 }} active />
			</Layout>
		);
	}

	const title = `${patient ? "Edit" : "New"} Patient`;

	return (
		<Layout title={title} pagetitle={title}>
			<CreateEditPatientForm patient={patient || null} />
		</Layout>
	);
};

export default CreateEditPatient;

import React, { FormEvent, useState, useEffect, useContext } from "react";
import { Form, Select, Button, Row, Col, message, DatePicker } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { Treatment, User, Patient, TreatmentRecord } from "../../../models";
import { post, get, put } from "../../../services";
import { StoreDispatch } from "../../../Store";
import moment from "moment";

interface CreateEditTreatmentProps extends FormComponentProps {
	patient: Patient;
	closeModal: () => void;
	getTreatments: () => any;
	initialValues: TreatmentRecord;
	editMode: boolean;
}

interface Values {
	name: string;
	side: string;
	startDate: object;
}

const CreateEditTreatment: React.FC<CreateEditTreatmentProps> = ({
	patient,
	form,
	closeModal,
	getTreatments,
	initialValues,
	editMode
}) => {
	const [users, setUsers] = useState<User[]>([]);
	const [treatments, setTreatments] = useState<Treatment[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const dispatch = useContext(StoreDispatch);

	const { getFieldDecorator } = form;
	const { Option } = Select;

	async function getConsultants() {
		try {
			const res = await get("/users");
			if (res.data) {
				setUsers(res.data.users.filter((user: User) => user.role === "consultant"));
			} else {
				message.error("Unexpected Server Error: Failed to load users.");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to load users.");
		}
	}

	async function getTreatmentsList() {
		try {
			const res = await get("/treatments");
			if (res.data) {
				setTreatments(res.data);
			} else {
				message.error("Unexpected Server Error: Failed to load treatment data.");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to load treatment data.");
		}
	}

	useEffect(() => {
		getConsultants();
		getTreatmentsList();
	}, []);

	async function editTreatment(data: Values) {
		try {
			const res = await put("/treatment-records/" + initialValues.id, {
				...data,
				patientId: patient.id,
				startDate: moment(data.startDate).format("YYYY-MM-DD"),
			});

			setLoading(false);

			if (res.data) {
				const treatments = await getTreatments();
				const editedTreatment = treatments.find(
					(treatment: TreatmentRecord) => treatment.id === res.data.id
				);
				// Set newly edited treatment as the active treatment
				if (editedTreatment) {
					dispatch({
						type: "SET_TREATMENT",
						payload: editedTreatment
					});
				}
				message.success("Treatment successfully updated");

				closeModal();
			} else {
				throw new Error("Request failed");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to update treatment.");
		}
	}

	async function createTreatment(data: Values) {
		try {
			const res = await post("/treatment-records", {
				patientId: patient.id,
				...data
			});

			setLoading(false);

			if (res.data) {
				const treatments = await getTreatments();
				const createdTreatment = treatments.find(
					(treatment: TreatmentRecord) => treatment.id === res.data.id
				);
				// Set newly created treatment as the active treatment
				if (createdTreatment) {
					dispatch({
						type: "SET_TREATMENT",
						payload: createdTreatment
					});
				}
				message.success("Treatment successfully created");

				closeModal();
			} else {
				throw new Error("Request failed");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to create treatment.");
		}
	}

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			console.log(values)
			if (!err) {
				setLoading(true);

				if (editMode) {
					editTreatment(values);
				} else {
					createTreatment(values);
				}
			}
		});
	}

	return (
		<Form layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item label="Select start date">
						{getFieldDecorator("startDate", {
							initialValue: editMode ? moment(initialValues.startDate) : moment(),
							rules: [
								{
									type: "object",
									required: true,
									message: "Pleas select a start date"
								}
							]
						})(<DatePicker format="DD/MM/YYYY" size="large" />)}
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16}>
				<Col span={11}>
					<Form.Item label="Select treatment">
						{getFieldDecorator("treatmentId", {
							...(editMode && { initialValue: initialValues.treatmentId }),
							rules: [
								{
									required: true,
									message: "Please select a treatment"
								}
							]
						})(
							<Select
								placeholder="Select treatment"
								showSearch
								optionFilterProp="children"
								filterOption={(input: string, option: any) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								allowClear
								dropdownMatchSelectWidth={false}
								disabled={editMode}
								loading={!treatments.length ? true : false}>
								<Option disabled value="">
									Select treatment
								</Option>
								{treatments
									.sort((a, b) =>
										`${a.treatmentType.name} ${a.bodyPart.name}`.localeCompare(
											`${b.treatmentType.name} ${b.bodyPart.name}`
										)
									)
									.map((treatment: Treatment) => {
										const treatmentName = `${treatment.treatmentType.name} ${treatment.bodyPart.name}`;
										return (
											<Option key={treatment.id} value={treatment.id}>
												{treatmentName}
											</Option>
										);
									})}
							</Select>
						)}
					</Form.Item>
				</Col>
				<Col span={5}>
					<Form.Item label="Select side">
						{getFieldDecorator("side", {
							...(editMode && { initialValue: initialValues.side }),
							rules: [
								{
									required: true,
									message: "Please select a side"
								}
							]
						})(
							<Select allowClear placeholder="Please select...">
								<Option value="Left">Left</Option>
								<Option value="Right">Right</Option>
								<Option value="NR">NR</Option>
							</Select>
						)}
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Select consultant">
						{getFieldDecorator("consultantId", {
							...(editMode && { initialValue: initialValues.consultantId }),
							rules: [
								{
									required: true,
									message: "Please select a consultant"
								}
							]
						})(
							<Select
								allowClear
								showSearch
								optionFilterProp="children"
								filterOption={(input: any, option: any) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								placeholder="Select consultant"
								loading={!users.length ? true : false}>
								<Option disabled value="">
									Select consultant
								</Option>
								{users
									.sort((a, b) =>
										`${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
									)
									.map((user: User) => {
										const fullName = `${user.title} ${user.firstName} ${user.lastName}`;
										return (
											<Option key={user.id} value={user.id}>
												{fullName}
											</Option>
										);
									})}
							</Select>
						)}
					</Form.Item>
				</Col>
			</Row>

			<Form.Item style={{ marginBottom: 0, textAlign: "right" }}>
				<Button style={{ marginRight: 10 }} size="large" htmlType="button" onClick={closeModal}>
					Cancel
				</Button>

				<Button size="large" loading={loading} type="primary" htmlType="submit">
					{editMode ? "Save" : "Create"} Treatment
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<CreateEditTreatmentProps>()(CreateEditTreatment);

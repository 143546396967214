import React, { useState, useEffect, Fragment, useContext } from "react";

import { Skeleton, Affix, Result, Divider, Layout, Button } from "antd";
import { get } from "../../../services";
import OSClinicLogo from "../../../static/images/OSClinicLogo.png";
import css from "../../../pages/patient-form/PatientForm.module.css";

import { ReviewRecord, Patient, Question, TreatmentRecord, Questionnaire } from "../../../models";
import Form from "../../patient-form/Form";

interface Props {
	// closeModal: () => void;
	closeModal: any;
	review: ReviewRecord;
	patient: Patient;
	questionnaire: Questionnaire;
}

const EditQuestionnaire = ({ closeModal, review, patient, questionnaire }: Props) => {
	const done = false;

	return patient && questionnaire && questionnaire.questions ? (
		<section className="questions">
			<Form
				maxPages={0}
				done={done}
				patient={patient}
				questionnaire={questionnaire}
				analystEdition={true}
				closeAnalystModal={closeModal}
				reviewRecordId={review.id}
			/>
			<Button
				style={{ position: "absolute", bottom: "24px", right: "24px" }}
				size="large"
				htmlType="button"
				onClick={closeModal}>
				Cancel
			</Button>
		</section>
	) : (
		!done && <Skeleton avatar active />
	);
};

export default EditQuestionnaire;

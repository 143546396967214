import API from "../lib/axiosConfig";

import { Response } from "../models";

export default async function post(endpoint: string, data: {}) {
	try {
		const response = await API.post(endpoint, data);
		return response as Response;
	} catch (error) {
		return error as Response;
	}
}

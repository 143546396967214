import React, { useState, useEffect, useContext, FormEvent } from "react";
import { ReviewType } from "../../models";
import { get, post } from "../../services";
import Layout from "../../components/layouts/Main";
import { List } from "antd";


const Reviews = () => {
	// State
	// UI control
	const [loading, setLoading] = useState<boolean>(true);
	const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false);
	const [selected, setSelected] = useState<ReviewType | null>(null);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [reviews, setReviews] = useState<null | [ReviewType]>(null);
	const [yearsFromTreatment, setYearsFromTreatment] = useState<number>(0);
	const [monthsFromTreatment, setMonthsFromTreatment] = useState<number>(0);

	// Fetch data
	const getData = async () => {
		const reviewsRes = await get("/review-types");
		setReviews(reviewsRes.data);
		setLoading(false);
	};

	// Get data on mount
	useEffect(() => {
		getData();
	}, []);

	const selectRow = (review: ReviewType) => () => {
		const yearsFull = Math.floor(+review.monthsFromTreatment / 12);
		const yearsFullInMonth = yearsFull > 0 ? yearsFull * 12 : 0;
		const month = +review.monthsFromTreatment - yearsFullInMonth;
		setYearsFromTreatment(yearsFull);
		setMonthsFromTreatment(month);
		setSelected(review);
		setEditMode(true);
		setShowCreateEditModal(true);
	};

	// POST/PUT data
	const createRecord = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const values = new FormData(event.currentTarget as HTMLFormElement);

		const yearsFromTreatmentInMonth = +values.get("yearsFromTreatment")! * 12;
		const monthsFromTreatment = +values.get("monthsFromTreatment")! + yearsFromTreatmentInMonth;

		const reqData = {
			monthsFromTreatment,
			name: values.get("name"),
			daysFromTreatment: +values.get("daysFromTreatment")!
		};

		getData();
		closeModal();
	};

	const closeModal = () => {
		setShowCreateEditModal(false);
		setEditMode(false);
		setSelected(null);
		setYearsFromTreatment(0);
		setMonthsFromTreatment(0);
	};

	return (
		<Layout title="Reviews" pagetitle="Reviews">
			{reviews ? (
				<List
					loading={loading}
					bordered
					dataSource={reviews.sort((r1: ReviewType, r2: ReviewType) =>
						r1.name.localeCompare(r2.name)
					)}
					renderItem={(item) => <List.Item>{item.name}</List.Item>}
				/>
			) : (
					<p>Loading...</p>
			)}

		</Layout>
	);
};

export default Reviews;
